// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --menu: #3e79f7;
  --menubg: #e6f7ff;
  --dark: rgb(47, 47, 47);
  --dropdawnbg: #fafafa;
  --background: #fafafb;
  --tab-next: #0d6efd;
  --subscribe: #18a695;
  --subscribe-text: #233342;
  --white: #ffffff;
  --black: rgba(0, 0, 0, 0.66);
  --danger: #ff0004;
  --chat-date: rgba(164, 164, 164, 0.46);
  --chat-bg: #f3f3f0;
  --curier-sms-bg: #f0f2f5;
  --user-sms-bg: #fff;
  --primary-text: #000;
  --ingrediend-text: rgb(0, 0, 0);
  --ingrediend-border: rgba(0, 0, 0, 0.05);
  --primary: #3e79f7;
  --sidebar-active: rgba(62, 121, 247, 0.1);
  --primary-hover: #699dff;
  --border-color: #e6ebf1;
  --border-color-bg: #fff;
  --border-color-bg-dark: #48566f;
  --grey: #ededed;
  --dark-background: #283142;
  --dark-grey: #4d5b75;
  --light-text: #d6d7dc;
  --dark-blue: #1b2531;
  --dark-red: #ff6b72;
  --dark-muted-text: rgba(114, 132, 154, 0.9);
  --dark-primary-hover: rgba(62, 121, 247, 0.1);
  --bg-color: #323d52;
  --global-bg: rgba(255, 255, 255, 0.28);
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/variables.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,qBAAA;EACA,qBAAA;EACA,mBAAA;EACA,oBAAA;EACA,yBAAA;EACA,gBAAA;EACA,4BAAA;EACA,iBAAA;EACA,sCAAA;EACA,kBAAA;EACA,wBAAA;EACA,mBAAA;EACA,oBAAA;EACA,+BAAA;EACA,wCAAA;EACA,kBAAA;EACA,yCAAA;EACA,wBAAA;EACA,uBAAA;EACA,uBAAA;EACA,+BAAA;EACA,eAAA;EACA,0BAAA;EACA,oBAAA;EACA,qBAAA;EACA,oBAAA;EACA,mBAAA;EACA,2CAAA;EACA,6CAAA;EACA,mBAAA;EACA,sCAAA;AACF","sourcesContent":[":root {\n  --menu: #3e79f7;\n  --menubg: #e6f7ff;\n  --dark: rgb(47, 47, 47);\n  --dropdawnbg: #fafafa;\n  --background: #fafafb;\n  --tab-next: #0d6efd;\n  --subscribe: #18a695;\n  --subscribe-text: #233342;\n  --white: #ffffff;\n  --black: rgba(0, 0, 0, 0.66);\n  --danger: #ff0004;\n  --chat-date: rgba(164, 164, 164, 0.46);\n  --chat-bg: #f3f3f0;\n  --curier-sms-bg: #f0f2f5;\n  --user-sms-bg: #fff;\n  --primary-text: #000;\n  --ingrediend-text: rgb(0, 0, 0);\n  --ingrediend-border: rgba(0, 0, 0, 0.05);\n  --primary: #3e79f7;\n  --sidebar-active: rgba(62, 121, 247, 0.1);\n  --primary-hover: #699dff;\n  --border-color: #e6ebf1;\n  --border-color-bg: #fff;\n  --border-color-bg-dark: #48566f;\n  --grey: #ededed;\n  --dark-background: #283142;\n  --dark-grey: #4d5b75;\n  --light-text: #d6d7dc;\n  --dark-blue: #1b2531;\n  --dark-red: #ff6b72;\n  --dark-muted-text: rgba(114, 132, 154, 0.9);\n  --dark-primary-hover: rgba(62, 121, 247, 0.1);\n  --bg-color: #323d52;\n  --global-bg: rgba(255, 255, 255, 0.28);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
