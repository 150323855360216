// project settings, you can change only PROJECT_NAME, BASE_URL and WEBSITE_URL otherwise it can break the app
export const PROJECT_NAME = 'Odeje marketplace';
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://api.odeje.my';
export const WEBSITE_URL = 'https://odeje.my';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const export_url = BASE_URL + '/public/storage/';
export const example = BASE_URL + '/public/';

// map api key, ypu can get it from https://console.cloud.google.com/apis/library
export const MAP_API_KEY = 'AIzaSyCLIFyH_KVcoE5quf8pRnDbOu97__qE3ps';

// firebase keys, do not forget to change to your own keys here and in file public/firebase-messaging-sw.js
export const VAPID_KEY =
  'BODOMQ4oh5U-5aLnR35O8VmUtobUiumPj6DmCNb8xx6iMRWRGsg09elBcEbkga_LmnB9j-RqsrJt0ZqovYjYHTE';
export const API_KEY = 'AIzaSyAwpRUzq3i6GOPELcfIRAzS-mNO649C3fA';
export const AUTH_DOMAIN = 'odeje-4c774.firebaseapp.com';
export const PROJECT_ID = 'odeje-4c774';
export const STORAGE_BUCKET = 'odeje-4c774.appspot.com';
export const MESSAGING_SENDER_ID = '270146221022';
export const APP_ID = '1:270146221022:web:0c47a06c9ab828a0eea34f';
export const MEASUREMENT_ID = 'G-9HG1HTKLMW';

// recaptcha key, you can get it from https://www.google.com/recaptcha/admin/create
export const RECAPTCHASITEKEY = '6LdAy0kqAAAAAOKTWx9Vcd4vJUDxaJjuxqRSHJk9';

// demo data, no need to change
export const LAT = 47.4143302506288;
export const LNG = 8.532059477976883;
export const DEMO_SELLER = 334; // seller_id
export const DEMO_SELLER_UUID = '3566bdf6-3a09-4488-8269-70a19f871bd0'; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
