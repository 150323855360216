// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.container .header {
  display: flex;
  margin-bottom: 20px;
}
.container .header .first-four,
.container .header .first-zero,
.container .header .second-four {
  font-size: 300px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/404.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;AAEJ;AADI;;;EAGE,gBAAA;AAGN","sourcesContent":[".container {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  text-align: center;\n  .header {\n    display: flex;\n    margin-bottom: 20px;\n    .first-four,\n    .first-zero,\n    .second-four {\n      font-size: 300px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
