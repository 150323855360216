// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 45px;
  height: 20px !important;
  outline: none !important;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}
.toggle .ant-switch-handle {
  top: -1px;
  left: -4px;
  position: absolute;
  background-color: #fff;
  border-color: #fff;
  border-radius: 50%;
}
.toggle .ant-switch-handle:before {
  width: 22px;
  height: 22px;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/switch.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACE,uBAAA;EACF,wBAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;AACF;AAAE;EACE,SAAA;EACA,UAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;AAEJ;AAAI;EACE,WAAA;EACA,YAAA;EACA,aAAA;AAEN","sourcesContent":[".toggle {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  width: 45px;\n    height: 20px !important;\n  outline: none !important;\n  border: none;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  .ant-switch-handle {\n    top: -1px;\n    left: -4px;\n    position: absolute;\n    background-color: #fff;\n    border-color: #fff;\n    border-radius: 50%;\n\n    &:before {\n      width: 22px;\n      height: 22px;\n      outline: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
