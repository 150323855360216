// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat {
  background: white;
  border-radius: 50%;
  padding: 5px;
  position: fixed;
  right: 24px;
  bottom: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 7px rgba(61, 61, 61, 0.2);
  z-index: 99;
}
.chat .chat-icon {
  font-size: 25px;
}

[data-theme=dark] .chat {
  background: #4d5b75;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/page/chat-icon.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yCAAA;EACA,WAAA;AACF;AAAE;EACE,eAAA;AAEJ;;AACA;EACE,mBAAA;AAEF","sourcesContent":[".chat {\n  background: white;\n  border-radius: 50%;\n  padding: 5px;\n  position: fixed;\n  right: 24px;\n  bottom: 24px;\n  width: 50px;\n  height: 50px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-shadow: 0 0 7px rgba(61, 61, 61, 0.2);\n  z-index: 99;\n  .chat-icon {\n    font-size: 25px;\n  }\n}\n[data-theme='dark'] .chat {\n  background: #4d5b75;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
