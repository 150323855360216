// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.extra-value {
  border-radius: 200px;
  padding: 4px 8px 4px 12px;
  background-color: var(--grey);
  display: flex;
  align-items: center;
  gap: 8px;
}

.extra-clear {
  outline: none;
  border: none;
  background-color: var(--dark-grey);
  color: var(--white);
  display: inline-flex;
  align-items: center;
  border-radius: 200px;
  cursor: pointer;
  padding: 4px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/extra.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,yBAAA;EACA,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;;AAEA;EACI,aAAA;EACA,YAAA;EACA,kCAAA;EACA,mBAAA;EACA,oBAAA;EACA,mBAAA;EACA,oBAAA;EACA,eAAA;EACA,YAAA;AACJ","sourcesContent":[".extra-value {\n    border-radius: 200px;\n    padding: 4px 8px 4px 12px;\n    background-color: var(--grey);\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.extra-clear {\n    outline: none;\n    border: none;\n    background-color: var(--dark-grey);\n    color: var(--white);\n    display: inline-flex;\n    align-items: center;\n    border-radius: 200px;\n    cursor: pointer;\n    padding: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
