// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-content {
  min-height: 100%;
  margin-top: 16px;
  padding-top: 50px;
  position: relative;
}
.steps-content .ant-form .send-button {
  position: absolute;
  right: 0;
  bottom: -28px;
}

.steps-action {
  margin-top: 24px;
}

.ant-steps-item-icon .ant-steps-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.table-data .produxt-data {
  margin-top: 5px;
  font-weight: bold;
  margin-left: 20px;
}
.table-data .me-1 {
  color: var(--subscribe);
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/product-add.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;AAEI;EACE,kBAAA;EACA,QAAA;EACA,aAAA;AAAN;;AAKA;EACE,gBAAA;AAFF;;AAME;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAHJ;;AAQE;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AALJ;AAQE;EACE,uBAAA;AANJ","sourcesContent":[".steps-content {\n  min-height: 100%;\n  margin-top: 16px;\n  padding-top: 50px;\n  position: relative;\n\n  .ant-form {\n    .send-button {\n      position: absolute;\n      right: 0;\n      bottom: -28px;\n    }\n  }\n}\n\n.steps-action {\n  margin-top: 24px;\n}\n\n.ant-steps-item-icon {\n  .ant-steps-icon {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n  }\n}\n\n.table-data {\n  .produxt-data {\n    margin-top: 5px;\n    font-weight: bold;\n    margin-left: 20px;\n  }\n\n  .me-1 {\n    color: var(--subscribe);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
